// testimonials
.testimonial-bubble{
  box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
  &::before{
    content: '';
    position: absolute;
    width: 30px;
    height: 1px;
    box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
    left: 50%;
    transform: translateX(-50%);
    bottom: -11px;
    border-radius: 50px;
    background: transparent;
  }
  &::after{
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    border-radius: 50px;
    background: white;
  }

  .testimonial-bubble2{
    &::after{
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -45px;
      border-radius: 45px;
      background: white;
    }
  }

  .testimonial-bubble3{
    &::after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.1);
      left: 50%;
      transform: translateX(-50%);
      bottom: -75px;
      border-radius: 45px;
      background: white;
    }
  }
}