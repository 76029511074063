.auth-page {
  .white-container{
    width: 330px;
    form{
      width: 100%;
      input[type="text"], input[type="password"], input[type="email"]{
        width: 100% !important;
      }
    }
  }
  .bg-circle{
    width: 465px;
    position: absolute;
    opacity: 0.1;
    &.position-top{
      top: -150px;
      left: -200px;
    }
    &.position-bottom{
      bottom: -150px;
      right: -200px;
    }
  }

  hr {
    height: 0;
    border-bottom: 1px solid #e2e8f0;
  }

  iframe {
    width: 100%;
    padding: 8px 14px;
    border: 1px solid #e2e8f0;
  }

  .btn-auth{
    border: 0;
    background : linear-gradient(90deg, rgba(24, 176, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%);
    border-radius : 3px;
    width: 100%;
    color: white;
    margin: 0 auto;
    opacity: 1;

    &:hover {
      filter: brightness(90%);
    }

    &:disabled {
      filter: none;
      cursor: not-allowed;
      opacity: 0.3;
    }

    &.btn-core {
      background: #080421;
      border-radius: 4px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .error-wrapper{
    background : #f9d4d9;
    background : rgba(249, 212, 217, 1);
    border-style : solid;
    border-color : #e0aab9;
    border-color : rgba(224, 170, 185, 1);
    border-width : 1px;
    border-radius : 3px;

    font-size : 14px;
    line-height : 20px;
    color : #B57C8D;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &.notice {
      background: #d4f9eb;
      border-color: #b5e0aa;
      color: #48bb78;
    }
  }

  .is-invalid + div {
    font-size: 12px;
  }

  // registration specific page
  .user_send_daily_summary{
    label{
      width: 100% !important;
    }
    select{
      width: 75% !important;
      margin-left: 0.75rem;
    }
  }
  .user_accepted_terms{
    margin-top: 0.75rem;
    label{
      width: 100% !important;
    }
  }
}
