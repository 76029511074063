// core styles
@import "tippy";

// custom
@import "background_shapes";
@import "demo";
@import "user_purchase_flow";
@import "order_summary";
@import "pricing";
@import "partner_landing";
@import "scroller";
@import "custom";
@import "blog";
@import "headroom";
@import "shared_elements";
@import "inputs";

.bg-core-benefits-2 {
  background: linear-gradient(60deg,
      rgba(2, 186, 148, 1) 0%,
      rgba(2, 144, 159, 1) 100%);

  @media (min-width: 1024px) {
    background: none;
    background-image: url("~images/core-bg-3.svg");
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 143vh;
  }
}

.curve-divide {
  @media (min-width: 1024px) {
    height: 25em;
  }
}

.case-studies-wrapper {
  @media (min-width: 1024px) {
    height: 150vh;
    max-height: 1200px;
  }
}

.landing-benefits-wrapper {
  @media (min-width: 1024px) {
    min-height: 800px;
    height: 100vh;
    max-height: 900px;

    // container for big screen
    >div {
      max-width: 1800px;
      padding: 1rem;
      margin: 0 auto;
    }
  }

  &.first-benefit {
    @media (min-width: 1024px) {
      min-height: 800px;
      height: 115vh;
      max-height: 1100px;
    }
  }
}

.quote-cta-wrapper {
  @media (min-width: 1024px) {
    height: 50vh;
    min-height: 500px;

    // container for big screen
    >div {
      max-width: 1500px;
      padding: 1rem;
      margin: 0 auto;
    }
  }
}

.modal {
  .is-invalid+div {
    font-size: 12px;
  }

  &.modal-lg {
    .modal-body {
      width: 100%;
      max-height: 100vh;
      overflow: auto;
    }
  }

  &.modal-md {
    .modal-body {
      width: 100%;
      max-height: 100vh;
      overflow: auto;
    }
  }
}

@media (min-width: 800px) {
  .modal {
    &.modal-lg {
      .modal-body {
        width: 800px;
      }
    }

    &.modal-md {
      .modal-body {
        width: 680px;
      }
    }
  }
}

@media (max-width: 940px) {
  .modal {
    .modal-body {
      max-height: 80vh;
    }
  }
}

// quiz
.location-type-wrapper {
  &.selected-location {
    img {
      box-shadow: 0 0 0 8px rgba(18, 179, 249, 1);
    }
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
  }

  &:hover {
    .text-wrapper {
      display: flex;
    }
  }
}

.quiz-purpose-input {
  background-color: transparent !important;
  border: none;
  color: white;
  border-bottom: 3px solid white;
  border-width: 3px !important;
  border-color: white !important;
  border-radius: 0 !important;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  &:focus {
    box-shadow: none !important;

    &::placeholder {
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}

// loading screen
.sk-circle {
  margin: 0 auto;
  margin-bottom: 50px;
  width: 150px;
  height: 150px;
  position: relative;

  &.sk-dark {
    margin-top: 50px;

    .sk-child {
      &:before {
        background-color: #2a283f;
      }
    }
  }
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.quiz-box-shadow {
  box-shadow: 0 -5px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.span-block {
  span {
    display: block;
  }
}

.modal-content {
  .dropdown-hour-container {
    overflow-y: visible;
    height: 300px;

    ul {
      max-height: 295px;
    }
  }
}

.pac-container {
  z-index: 1020;
}

.btn {
  user-select: none;

  &.red-dot {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: -5px;
      right: -5px;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      z-index: 90;
      background-color: #f56565;
    }
  }
}

.github-fork-ribbon.red {
  &:before {
    background-color: #a00;
  }

  &:after {
    background-color: #a00;
  }
}

.github-fork-ribbon.green {
  &:before {
    background: linear-gradient(60deg, #02ba94 0%, #02909f 100%);
    background-image: none;
    box-shadow: none;
  }

  &:after {
    background: linear-gradient(60deg, #02ba94 0%, #02909f 100%);
    border-width: 0;
    text-shadow: none;
  }
}

.footer-links.event-page {

  a,
  a:hover {
    text-decoration: none;
  }

  &.core {
    a {

      // background-color: #065842;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.bg-black {
  &.bg-core {
    background-color: #080421;
  }
}

.btn-primary {
  &.btn-core.transparent {
    background: rgba(4, 33, 8, 0.6) !important;
  }
}

.select-multiple {
  overflow: visible;
}

.loader {
  &.core {
    border-top: 7px solid rgba(88, 227, 137, 0.2);
    border-right: 7px solid rgba(88, 227, 137, 0.2);
    border-bottom: 7px solid rgba(88, 227, 137, 0.2);
    border-left: 7px solid #58e3c9;
  }
}

.slick-phone {
  visibility: hidden;

  &.slick-initialized {
    visibility: visible;
  }
}

.slick-paged-list {
  visibility: hidden;

  &.slick-initialized {
    visibility: visible;
  }
}

.slick-events {
  overflow: hidden;

  &:not(.slick-initialized) {
    display: flex;
    position: relative;

    >div:nth-child(n+1) {
      overflow: hidden;
      width: 0;
    }

    >div:first-child {
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;

      >div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: 20px;

    li {
      width: 40px;

      &:last-child {
        width: 20px;
      }

      button {
        &::before {
          font-size: 20px;
          color: white;
        }
      }
    }
  }

  .slick-arrow {
    @media (max-width: 1023px) {
      display: none !important;
    }

    z-index: 130;
    width: 86px;
    height: 86px;
    color: #fff;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.35);

    &:hover {
      color: #fff;
      opacity: 0.66;
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      opacity: 1;
      font-size: 60px;
    }

    &.slick-prev {
      left: 25px;

      &::before {
        content: "";
      }
    }

    &.slick-next {
      right: 25px;

      &::before {
        content: "";
      }
    }
  }
}

.core-carousel-slick {
  .slick-list {
    border-radius: 13px;
    overflow: hidden;
    background: #fff;
  }

  .slick-slide {
    outline: none;
  }

  .slick-dots {
    bottom: 20px;

    li {
      width: 30px;

      &:last-child {
        width: 20px;
      }

      button {
        &::before {
          font-size: 15px;
          color: #2a283f;
        }
      }

      &.slick-active {
        button {
          &::before {
            color: #02ba94;
          }
        }
      }
    }
  }

  .slick-arrow {
    @media (max-width: 1023px) {
      display: none !important;
    }

    z-index: 130;
    width: 86px;
    height: 86px;
    background-color: #080421;
    border: 4px solid #608dfa;
    border-radius: 17px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.15),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: #120850;
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      opacity: 1;
      font-size: 40px;
    }

    &.slick-prev {
      left: -50px;

      &::before {
        content: "";
        margin-left: -4px;
      }
    }

    &.slick-next {
      right: -50px;

      &::before {
        content: "";
        margin-right: -4px;
      }
    }
  }
}

.testimonial-slice {
  visibility: hidden;

  &.slick-initialized {
    visibility: visible;
  }
}

.js-welcome-proposal-modal {
  visibility: hidden;
  height: 243px;
  width: 452px;

  &.slick-initialized {
    visibility: visible;
  }

  .slick-arrow {
    z-index: 130;
    width: 20px;
    height: 20px;
    color: black;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.35);

    &:hover {
      color: black;
      opacity: 0.66;
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      opacity: 1;
      font-size: 26px;
      color: black;
    }

    &.slick-prev {
      left: 25px;

      &::before {
        content: "";
      }
    }

    &.slick-next {
      right: 25px;

      &::before {
        content: "";
      }
    }
  }
}

.theme-carousel {
  position: relative;

  .theme-slider {
    overflow: hidden;
    padding-top: 1.5rem;
    margin-bottom: 0;
    visibility: hidden;
    margin-bottom: 0px !important;

    &.centered {
      .slick-slide {
        align-items: center;
        justify-content: center;
      }
    }

    .theme-component {
      display: none;
    }

    .theme-component:first-child {
      display: block;
    }

    &.slick-initialized {
      visibility: visible;

      .theme-component {
        display: block;
      }
    }

    .slick-track {
      display: inline-flex;
    }

    .slick-slide {
      margin: 0 1rem;
      display: flex;
      height: auto;
    }

    .slick-list {
      margin: 0 -1rem;
    }

    .slick-dotted.slick-slider {
      margin-bottom: 0px !important;
    }

    .force-flex {
      display: flex !important;
    }

    .bitesize-dots {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        height: 18px;
        width: 18px;
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        font-size: 0;

        &.slick-active {
          height: 22px;
          width: 22px;
        }
      }

      li:not(:first-child) {
        margin: 0 0 0 14px;
      }
    }
  }

  .slick-arrow {
    display: none;
    z-index: 130;
    width: 86px;
    height: 86px;
    background-color: #080421;
    border: 4px solid #608dfa;
    border-radius: 43px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.15),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    &.bitesize-arrow {
      top: 6rem;
      width: 67px;
      height: 67px;
    }

    &:hover {
      background-color: #120850;
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      opacity: 1;
      font-size: 40px;
    }

    &.slick-prev {
      left: -43px;

      &::before {
        content: "\f060";
        height: 25px;
        width: 25px;
        margin-left: -4px;
      }
    }

    &.bitesize-left {
      left: -55px;

      &::before {
        font-size: 30px;
      }
    }

    &.slick-next {
      right: -43px;

      &::before {
        content: "\f061";
        margin-right: -4px;
      }
    }

    &.bitesize-right {
      right: -55px;

      &::before {
        font-size: 30px;
      }
    }

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-dots {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    width: auto;
    display: flex;

    li {
      margin: 0;
      margin-left: 1px;
      height: auto;
      width: auto;

      button {
        padding: 0;
        width: 20px;
        height: 4px;
        background-color: #d8d8d8;
        border-radius: 2px;

        &:before {
          content: none;
        }
      }

      &.slick-active {
        button {
          background-color: #000000;
        }
      }
    }
  }
}

@media (max-width: 940px) {
  .theme-carousel {
    .slick-arrow {
      width: 64px;
      height: 64px;
      border-width: 3px;

      &::before {
        font-size: 30px;
      }

      &.slick-prev {
        left: -1rem;

        &::before {
          margin-left: -2px;
        }
      }

      &.slick-next {
        right: -1rem;

        &::before {
          margin-right: -2px;
        }
      }
    }
  }
}

.scroll-gradient {
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0,
      rgba(158, 158, 158, 0.5),
      rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%,
      rgba(158, 158, 158, 0.5),
      rgba(0, 0, 0, 0)) 0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 24px, 100% 24px, 100% 8px, 100% 8px;
}

.location-select {
  &.core-icon {
    background: url("~images/core_location_pin.svg") no-repeat scroll 12px 10px;
    background-color: #fff;
    background-size: 18px;
    padding-left: 39px !important;
  }
}

.white-checkbox {
  label a {
    color: #fff !important;
  }
}

.filled-progress-bar {
  transition: 0.5s width linear;
}

.animate-opacity {
  transition: opacity 0.15s ease-in-out;
}

.animate-background-color {
  transition: background-color 0.15s ease-out;
}

.hero-buttons {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// content

.main-body {

  p,
  ul,
  ol {
    font-size: 18px;
    line-height: 32px;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a202c;
    line-height: 1;
  }

  h1+* {
    margin-top: 2rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #1a202c;
    line-height: 1.25;
  }

  *+h2 {
    margin-top: 2rem;
  }

  h2+* {
    margin-top: 1rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a202c;
    line-height: 1.25;
  }

  *+h3 {
    margin-top: 1.5rem;
  }

  h2+h3 {
    margin-top: 1rem;
  }

  h3+* {
    margin-top: 0.5rem;
  }

  h4,
  h5,
  h6 {
    font-size: 18px;
    font-weight: 700;
    color: #1a202c;
    line-height: 1.5;
  }

  *+h4,
  *+h5,
  *+h6 {
    margin-top: 1.5rem;
  }

  h3+h4,
  h3+h5,
  h3+h6,
  h4+h5,
  h4+h6,
  h5+h6 {
    margin-top: 0.5rem;
  }

  h4+*,
  h5+*,
  h6+* {
    margin-top: 0.5rem;
  }

  p {
    font-weight: 400;
    color: #2d3748;
    line-height: 1.625;
  }

  p+p {
    margin-top: 1rem;
  }

  strong {
    font-weight: 700;
    color: #1a202c;
  }

  a,
  a strong {
    font-weight: 700;
    color: #18b0f9 !important;
  }

  a:hover {
    text-decoration: underline;
  }

  code {
    background-color: #edf2f7;
    font-size: 0.875em;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  img {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  [data-oembed] {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .block-img img {
    width: 100%;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.25rem;
  }

  *+ol {
    margin-top: 1rem;
  }

  ol+* {
    margin-top: 1rem;
  }

  li ol {
    margin-top: 0.5rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.25rem;
  }

  *+ul {
    margin-top: 1rem;
  }

  ul+* {
    margin-top: 1rem;
  }

  li ul {
    margin-top: 0.5rem;
  }

  li {
    font-weight: 400;
    color: #2d3748;
    line-height: 1.625;
  }

  li+li {
    margin-top: 0.5rem;
  }

  li p {
    margin-top: 1rem;
  }

  li p+p {
    margin-top: 0.5rem;
  }

  li:first-child p:first-child {
    margin-top: 0.5rem;
  }

  blockquote {
    font-style: italic;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #e2e8f0;
    padding-left: 1rem;
  }

  *+blockquote {
    margin-top: 1rem;
  }

  blockquote+* {
    margin-top: 1rem;
  }

  pre {
    background-color: #edf2f7;
    padding-top: 0.75rem;
    padding-right: 1rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
  }

  *+pre {
    margin-top: 1.5rem;
  }

  pre+* {
    margin-top: 1.5rem;
  }

  script+* {
    margin-top: 1.5rem;
  }

  pre code {
    font-size: 0.875rem;
    padding: 0;
  }

  p>.left-aligned-image,
  p>.right-aligned-image {
    display: none;
  }

  .left-aligned-image-wrapper {
    display: flex;
    flex-direction: row-reverse;

    >div,
    >p {
      flex: 1;
    }

    .block-img {
      margin-right: 1.5rem;

      img {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .right-aligned-image-wrapper {
    display: flex;

    >div,
    >p {
      flex: 1;
    }

    .block-img {
      margin-left: 1.5rem;

      img {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.paragraph-content {
  ol {
    list-style-type: decimal;
    padding-left: 1.25rem;
  }

  *+ol {
    margin-top: 1rem;
  }

  ol+* {
    margin-top: 1rem;
  }

  li ol {
    margin-top: 0.5rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.25rem;
  }

  *+ul {
    margin-top: 1rem;
  }

  ul+* {
    margin-top: 1rem;
  }

  li ul {
    margin-top: 0.5rem;
  }

  li {
    line-height: 1.625;
  }

  li+li {
    margin-top: 0.5rem;
  }

  li p {
    margin-top: 1rem;
  }

  li p+p {
    margin-top: 0.5rem;
  }

  li:first-child p:first-child {
    margin-top: 0.5rem;
  }
}

.horizontal-line-bottom {
  position: relative;
  padding-bottom: 1rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 12rem;
    border-radius: 15px;
    height: 4px;
  }

  &.line-w-full {
    &::before {
      width: 100%;
    }
  }

  &.line-long {
    &::before {
      width: 237px;
    }
  }

  &.line-green {
    &::before {
      background: #02ba94;
    }
  }

  &.line-purple {
    &::before {
      background: #7e7ff9;
    }
  }
}

.bg-wg-green-gradient {
  background: linear-gradient(60deg,
      rgba(2, 186, 148, 1) 0%,
      rgba(2, 144, 159, 1) 100%);
}

.hover\:bg-wg-green-gradient:hover {
  background: linear-gradient(60deg,
      rgba(2, 186, 148, 1) 0%,
      rgba(2, 144, 159, 1) 100%);
}

.bg-wg-gray {
  background: #f7f7f7;
}

.social-icons {
  .fab {
    height: 80px;
    width: 80px;
    font-size: 36px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #080421;
      border-width: 3px;
      border-color: #4299e1;
    }
  }

  a+a {
    margin-top: 25px;
  }

  @media (min-width: 1024px) {
    a+a {
      margin-top: 0;
      margin-left: 25px;
    }
  }
}

.range-tooltip {
  position: absolute;
  z-index: 50;
  display: none;

  .range-tooltip-arrow {
    position: absolute;
    display: block;
    width: 7px;
    height: 7px;
    background: transparent;
    border-style: solid;
    border-width: 3.5px;
    transform: rotate(45deg);

    left: calc(50% - 3.5px);
    top: -3.5px;
    border-top-color: #e2e8f0;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #e2e8f0;
  }

  .range-tooltip-inner {
    background-color: #fff;
    min-width: 30px;
    min-height: 42px;
    padding: 8px 16px;
    border-color: #e2e8f0;
    border-width: 1px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
  }
}

.dark-core {
  input.is-invalid {
    border-color: #9c0101 !important;

    +.text-red-700 {
      color: #fff !important;
      font-size: 12px;
    }
  }
}

.pagy-wrapper {
  .page {
    margin: 0 1em;

    &.disabled {
      opacity: 0.33;
    }

    &.active {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.enquiry-flag {
  display: none;
  position: fixed;
  transform: translateX(0.875em) rotate(-90deg) scale(1.02);
  transform-origin: 100% 100%;
  top: 15%;
  right: 0;
  box-shadow: -7px 0px 25px -5px rgba(0, 0, 0, 0.2),
    0px 0px 10px -5px rgba(0, 0, 0, 0.14);
  transition: all 0.25s;

  &:hover {
    transform: translateX(0.5em) rotate(-90deg) scale(1.005);
    box-shadow: -7px 0px 25px -5px rgba(0, 0, 0, 0.5),
      0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }

  @media (min-width: 1024px) {
    display: block;
  }
}

.statistic-circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.pagy-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 0.7em;
  }

  .page {
    margin: 0 0.5em;
    min-width: 2em;

    @media (max-width: 768px) {
      margin: 0 0.3em;
    }

    a,
    &.active,
    &.disabled {
      background: #efefef;
      border: 1px solid #aaaaaa;
      color: #545454;
      border-radius: 4px;
      padding: 0.2em 0.6em;

      @media (max-width: 768px) {
        padding: 0.2em 0.4em;
      }
    }

    &.active,
    &:hover a {
      background: darken(#efefef, 60%);
      color: #ffffff;
      text-decoration: none;
    }

    a {
      position: relative;
      top: 0.3em;
    }

    &.gap {
      color: #545454;
    }
  }
}

@media (screen\0) {
  .object-cover {
    font-family: "object-fit: cover;";
  }
}

@media (screen\0) {
  .object-contain {
    font-family: "object-fit: contain;";
  }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .ms-hide {
    display: none;
  }
}

.simple-calendar td:hover {
  background-color: #eee;
}

input[type="range"].dark-thumb::-webkit-slider-thumb {
  background-color: #080421 !important;
}

input[type="range"].dark-thumb::-moz-range-thumb {
  background-color: #080421 !important;
}

input[type="range"].dark-thumb::-ms-thumb {
  background-color: #080421 !important;
}

.partner-sites-scroller {
  max-height: 264px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #aaa;
  }

  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.case-study-wrapper {
  transition: all 0.25s ease !important;
  height: auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-0.5rem);
  }
}

.calculator-input-wrapper {
  position: relative;

  input[type="number"] {
    font-size: 1.25rem;
    font-weight: 700;
    border: none;
    box-shadow: none;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 15px;
    height: 4px;
    border-radius: 2px;
  }

  &.goose-green {
    &::after {
      background: #2BB096;
    }
  }

  &.goose-purple {
    &::after {
      background: #9055e2;
    }
  }
}

.input-within-sentence {
  background-color: white !important;
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 5px 0 0 5px !important;
  padding: 0 !important;
  color: #09101D;
  text-align: center;
  font-weight: 600;
  width: 50px;
  height: 30px;
  font-size: 1rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    box-shadow: none !important;
  }
}

@import "media_queries";