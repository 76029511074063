@media screen and (min-width: 1020px) and (max-height: 800px) {
  .booking-flow {
    .main-bg {
      > .bg-white {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    nav:not(.progression) {
      > .container {
        padding: 0.5rem 0;
      }
      div {
        font-size: 16px;
      }
    }
    .progression {
      top: 3.5rem !important;
      > .container {
        padding-bottom: 1rem;
        padding-top: 0;
      }
      a, span {
        font-size: 13px;
      }
    }

    .theme-slider {
      padding-top: 0;

      img {
        max-height: 10rem;
      }
    }

    #purchase-footer {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      img {
        display: none;
      }
      span, p, a, input, button {
        font-size: 13px;
      }
    }

    .bottom-sticky {
      bottom: 3.75rem !important;
      font-size: 13px;
    }

    .xl\:text-3xl {
      font-size: 18px;
    }
    .xl\:text-base {
      font-size: 14px;
    }
  }
}
