@import "base_styles";

// GENERAL DOCUMENT STYLING
body{
  @apply font-proxima;
  -webkit-font-smoothing: antialiased;
  color: #080421;

  &.modal-shown {
    overflow: hidden;
  }
}

.css-transitions-only-after-page-load * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.33);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.33);
  }
}

h1{
  @apply text-2xl;
}

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="tel"], textarea {
  @apply bg-white border border-gray-300 rounded py-2 px-4 appearance-none leading-normal;

  &:focus {
    outline: none;
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  &.focus\:shadow-none {
    &:focus {
      box-shadow: none;
    }
  }
  &::placeholder {
    color: #CFD7DF;
    opacity: 1.0;
  }
  &.copyable {
    @apply bg-gray-100;
    @apply rounded-r-none;

    &:focus {
      box-shadow: none;
    }
  }
  &.readonly {
    @apply bg-gray-100;
    pointer-events: none;
    user-select: none;
  }

  &.button-connected{
    @media (min-width: 1024px) {
      @apply rounded-r-none;
    }
  }
}
select{
  @apply bg-white border border-gray-300 rounded py-2 px-4 appearance-none leading-normal;
  @apply relative;

  background-image: linear-gradient(45deg, transparent 50%, #e2e8f0 50%), linear-gradient(135deg, #e2e8f0 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
}

input.is-invalid, select.is-invalid {
  @apply border-red-700;
}
.must-accept {
  .error-dialog {
    display: none;
  }

  &.with-error {
    .user_accepted_terms {
      label > p {
        color: #c53030;
        font-weight: bold;
      }
    }

    .error-dialog {
      display: block;
    }
  }
}

// CUSTOM CLASSES

.text-area-input{
  @apply bg-white border border-gray-300 rounded py-2 px-4 appearance-none leading-normal;
  font-family: inherit !important;
  font-size: 100% !important;
  line-height: 1.15 !important;

  &:focus {
    outline: none;
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  &::placeholder {
    color: #CFD7DF;
    opacity: 1.0;
  }
  &.copyable {
    @apply bg-gray-100;
    @apply rounded-r-none;

    &:focus {
      box-shadow: none;
    }
  }
  &.readonly {
    @apply bg-gray-100;
    pointer-events: none;
    user-select: none;
  }
}
// backgrounds with gradients
.bg-core-primary-gradient{
  background: linear-gradient(90deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
}

.bg-lg-core-primary-gradient {
  @media (min-width: 767px) {
    background: linear-gradient(90deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
  }
}


.bg-max-md-core-primary-gradient{
  @media (max-width: 767px) {
    background: linear-gradient(90deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
  }
}

.bg-core-primary-gradient-rev{
  background: linear-gradient(-90deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%);
}

.bg-wg-purple-50{
  background : linear-gradient(90deg, rgba(144, 85, 226, 0.1) 0.95%, rgba(118, 96, 226, 0.1) 100%);
}

.bg-wg-blue-gradient{
  background : linear-gradient(90deg, rgba(18, 179, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%);
}

.bg-wg-orange-gradient{
  background : linear-gradient(90deg, rgba(250, 139, 128, 1) 0%, rgba(254, 207, 146, 1) 96.04%);
}

.bg-wg-red-gradient{
  background : linear-gradient(90deg, rgba(226, 85, 85, 1) 0%, rgba(226, 96, 132, 1) 96.04%);
}

.bg-wg-purple-gradient{
  background : linear-gradient(90deg, rgba(144, 85, 226, 1) 0.12%, rgba(118, 96, 226, 1) 100%);
}

.bg-wg-purple-gradient-tilted{
  background : linear-gradient(-60deg, rgba(144, 85, 226, 1) 0.12%, rgba(118, 96, 226, 1) 100%);
}


.bg-wg-blue-gradient-rev{
  background : linear-gradient(-90deg, rgba(18, 179, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%);
}

.bg-wg-primary-gradient{
  background : linear-gradient(60deg, rgba(144, 85, 226, 1) 0.95%, rgba(118, 96, 226, 1) 100%) !important;
}

.bg-wg-primary-gradient-rev{
  background : linear-gradient(-60deg, rgba(144, 85, 226, 1) 0.95%, rgba(118, 96, 226, 1) 100%);
}

.bg-lg-wg-primary-gradient {
  @media (min-width: 767px) {
    background : linear-gradient(60deg, rgba(144, 85, 226, 1) 0.95%, rgba(118, 96, 226, 1) 100%) !important;
  }
}

.primary-border{
  border-color: linear-gradient(-90deg, rgba(18, 179, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%);
}

.bg-white-semitransparent{
  background : rgba(255, 255, 255, 0.9);
}

.top-70 {
  top: 70px;
}

.z-150 {
  z-index: 150;
}

//
// backgrounds with gradients + reverse on hover
.bg-primary-gradient-reverse-on-hover{
  transition: all 0.5s ease;
  transition-property: box-shadow, background;
  background: linear-gradient(90deg, rgba(144, 85, 226, 1) 0.12%, rgba(118, 96, 226, 1) 100%);
  background-size: 200% 100%;

  &.core {
    background: linear-gradient(90deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%);
    background-size: 200% 100%;
  }
  &:hover{
    background-position: 100%;
  }
}

//
.outline-green {
  box-shadow: 0 0 0 5px #00BA93;
}

// box shadows who look act as borders
.border-shadow-3{
  box-shadow: 0 0 0 3px #9F7AEA;

  &.core{
    box-shadow: 0 0 0 3px #319795;
  }
}

.border-shadow-gray{
  box-shadow: 0 0 0 1px #a0aec0;
}

.-top-1px {
  top: -1px;
}

.children-paragraph-padding{
  p{
    padding-bottom: 1rem;;
  }
}

.text-slice {
  b, strong {
    @apply font-black;
  }


  h2 {
    @apply text-5xl tracking-wider font-bold my-2;
    @screen lg {
      @apply text-6xl;
    }
  }

  h3 {
    @apply text-3xl font-semibold leading-tight my-2;
    @screen lg {
      @apply text-4xl;
    }
  }

  h4 {
    @apply text-wg-green-300 text-2xl font-extrabold my-2;
    @screen lg {
      @apply text-3xl;
    }

  }

  h5 {
    @apply font-black uppercase text-lg rounded-md tracking-widest my-2;
    @screen lg {
      @apply text-xl;
    }
  }

  h6 {
    @apply font-black uppercase text-base rounded-md tracking-widest my-2;
    @screen lg {
      @apply text-lg;
    }
  }

  ul, ol {
    @apply my-4;

    li {
      @apply py-1;
    }
  }

  ol {
    li {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }

  .block-img img {
    margin: 0 auto;
  }
}

// Note that apply will not work for inlining pseudo-class or responsive variants of another utility.
// Instead, apply the plain version of that utility into the appropriate pseudo-selector or a new media query

// buttons
.btn{
  @apply rounded-full py-2 px-8 text-white bg-black cursor-pointer;
  @apply whitespace-nowrap;
  background-clip: padding-box;
}

.btn-square{
  @apply rounded-lg py-2 px-4 text-white bg-black cursor-pointer;
  background-clip: padding-box;
}

.btn-primary{
  @apply border-3 border-blue-500;
  @apply font-semibold;

  &.btn-core{
    border-color: #38A1F9;
  }

  &.btn-outline{
    background: none;
    color: black;

    &:hover{
      color: white;
    }
  }

  &.no-fill{
    background: none;
    border-color: white;
  }

  &.transparent{
    background: rgba(8, 4, 33, 0.6) !important;
  }
  &.colorful{
    background : linear-gradient(90deg, rgba(24, 176, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%) !important;
  }
  &.colorful-core{
    background: linear-gradient(60deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
  }

  &.disabled, &:disabled {
    @apply opacity-25 cursor-not-allowed;
  }
}

.overlay-transparent{
  background: rgba(8, 4, 33, 0.6) !important;

  &.border-top {
    @apply border-t-3 border-blue-500;
  }
}

.btn:hover {
  @apply bg-blue-700;
  filter: brightness(90%);

  &.disabled, &:disabled {
    @apply bg-black;
    filter: none;
  }
}

.btn-outline.btn-dark-hover{
  &:hover{
    filter: brightness(100%);
  }
}

.group:hover {
  .btn.btn-primary {
    @apply bg-blue-700;
    filter: brightness(90%);
  }
}

.btn-gray{
  @apply my-3 px-8 py-2;
  @apply bg-gray-800;
  @apply text-sm font-bold;

  &.selected {
    @apply bg-blue-700;
    filter: brightness(90%);
  }
}

.btn-danger {
  @apply bg-red-400;

  &:hover {
    @apply bg-red-400;
    filter: brightness(95%);
  }
}

.btn-black{
  @apply bg-black;
  @apply text-white;
  @apply font-medium;
}

.btn-white-outline{
  @apply bg-transparent;
  @apply text-white border-3 border-white;
  @apply font-medium;

  &.semi-transparent{
    background: rgba(0,0,0,0.4);
  }

  &.disabled, &:disabled {
    @apply cursor-not-allowed;
    @apply opacity-25;
  }
}

.btn-white-outline:hover{
  @apply text-black;
  background: white !important;
  filter: brightness(100%);

  &.disabled {
    @apply text-white;
    background-color: transparent !important;
    filter: brightness(100%);
  }
}

.btn-black-outline {
  @apply bg-transparent;
  @apply text-black border-3 border-black;
  @apply font-medium;

  &.semi-transparent {
    background: rgba(0, 0, 0, 0.4);
  }

  &.disabled,
  &:disabled {
    @apply cursor-not-allowed;
    @apply opacity-25;
  }
}

.btn-black-outline:hover {
  @apply text-white;
  background: black !important;
  filter: brightness(100%);

  &.disabled {
    @apply text-black;
    background-color: transparent !important;
    filter: brightness(100%);
  }
}

.btn-white{
  @apply bg-white;
  @apply text-black;

  &.semi-transparent{
    background: rgba(255,255,255,0.9);
  }
}

.btn-box-white {
  @apply bg-white;
  @apply text-black;

  &:hover {
    @apply bg-white;
    filter: brightness(95%);
  }
}

.btn-plan {
  @apply m-0 my-1 py-3 w-200px;
  @apply text-base text-center;

  &.disabled, &:disabled {
    @apply cursor-not-allowed;
    @apply opacity-25;

    &:hover {
      @apply bg-gray-800;
    }
  }
}

.btn-blue-outline{
  background: none;
  @apply border-3 border-wg-cyan-700;
  @apply font-semibold text-wg-cyan-700;

  &:hover, &:focus {
    background: #00909F;
    @apply text-white;
  }
}

.btn-colorful{
  border: 0;
  background : linear-gradient(90deg, rgba(24, 176, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%);
  border-radius : 3px;
  width: 100%;
  margin: 0 auto;
  opacity: 1;

  @apply cursor-pointer py-4 font-bold my-3 text-center text-white;

  &.core{
    background: linear-gradient(60deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
  }

  &.selected{
    background: #48bb78 !important;
  }

  &.deselect{
    background: #e53e3e !important;
  }
}

.btn-external {
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: medium;
  font-size: 1.25rem;
  line-height: 1.75rem;
  border: solid black 2px;
  padding: 1rem 2rem;
  transition: 150ms ease-out;
  width: fit-content;
  background-color: transparent;
}

.btn-external:hover {
  background-color: #00BA93;
  color: white;
  border-color: #00BA93;
  transition: 150ms ease-in;
}

.theme-item-border {
  border-color: #e2e8f0;

  &.selected {
    border-color: #48bb78 !important;
  }

}

.hover-darken {
  &:hover {
    filter: brightness(95%);
  }
}

.rounded-xl-top-only {
  border-radius: 12px 12px 0 0;
}

.top-lozenge {
  position: absolute;
  left: 50%;
  top: -12px;
  transform: translate(-50%, -50%);
  padding: 2px 15px 4px 15px;
  border-radius: 8.5px 8.5px 0 0;
}

.progress-dot {
  position: absolute;
  left: calc(50% - 6px);
  bottom: -6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #fff;
}

.progress-line-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #fff;

  &.incomplete {
    opacity: 0.4;
  }
}

.progress-line-2 {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #fff;

  &.incomplete {
    opacity: 0.4;
  }
}

.progress-dot-green {
  position: absolute;
  left: calc(50% - 6px);
  bottom: -6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #04888F;
}

.progress-line-1-green {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #04888F;

  &.incomplete {
    background-color: #DDDDDD;
    opacity: 0.4;
  }
}

.progress-line-2-green {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #04888F;

  &.incomplete {
    background-color: #DDDDDD;
    opacity: 0.4;
  }
}

.progress-dot-black {
  position: absolute;
  left: calc(50% - 6px);
  bottom: -6px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #000;
}

.progress-line-1-black {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #000;

  &.incomplete {
    background-color: #DDDDDD;
    opacity: 0.4;
  }
}

.progress-line-2-black {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50%;
  height: 1px;
  background-color: #000;

  &.incomplete {
    background-color: #DDDDDD;
    opacity: 0.4;
  }
}

// dashboard

//
.bg-purple-absolute{
  @apply absolute w-full bg-wg-primary-gradient;
}

.content-wrapper{
  @apply w-full relative;
  padding-top: 75px;
  padding-bottom: 75px;
  @media (min-width: 1024px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.dashboard-wrapper{

}

.dashboard-item-wrapper {
  @apply rounded-lg;
  @apply shadow-md;
}

.dashboard-item{
  @apply rounded-lg bg-white px-4 py-4 mb-5;
}

.dashboard-item-has-addon {
  .dashboard-item {
    @apply mb-0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dashboard-item-addon {
    @apply mb-5;
    @apply rounded-lg;
    @apply bg-green-500;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.invoice-item{
  @apply flex items-center justify-between;
  @apply rounded-lg bg-white px-4 py-2 h-80px mb-5;
}

//
.order-status{
  @apply inline-block rounded-full px-6 py-1 border font-bold text-xs uppercase;
}

.order-status:hover{
}

.order-status-confirmed{
  @apply border-green-500 text-green-500;
}
.order-status-cancelled{
  @apply border-red-500 text-red-500;
}
.order-status-needs-payment{
  @apply border-gray-500 text-gray-500;
}
.order-status-paid{
  @apply border-green-500 text-green-500;
}
.order-status-upcoming{
  @apply border-gray-500 text-gray-500;
}

// order-type
.orders-type{
  @apply bg-transparent text-white border-2 border-white;
  @apply px-3 py-2;
}
.orders-type-selected{
  @apply bg-white text-black;
}
.orders-type-upcoming{
  @apply rounded-l-full;
}
.orders-type-previous{
 @apply rounded-r-full;
}

// paging
.pager-item.current {
  @apply bg-white px-4 py-2 shadow-md rounded-lg mx-2;
}

.pager-item:not(.current) {
  a {
    @apply bg-white px-4 py-2 shadow-md rounded-lg mx-2;

    &:hover {
      @apply bg-gray-100;
    }
  }
}

// overlay
.overlay {
  @apply fixed top-0 bottom-0 right-0 left-0;
  @apply flex justify-center items-center;
  @apply bg-wg-primary-gradient;
  z-index: 1001;
  background-color: rgba(0,0,0,0.75);
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &.no-animation {
    opacity: 1;
    display: none;

    &.active {
      display: flex;
    }
  }

  .iframe {
    max-height: 100%;
    padding: 0;

  }

  &.core{
    @apply bg-core-primary-gradient;
  }
}
body.core {
  .overlay{
    @apply bg-core-primary-gradient;
  }
}

.container-lg {
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
}

// modal
.modal {
  @apply fixed top-0 bottom-0 right-0 left-0;
  @apply flex justify-center items-center;
  z-index: 1001;
  background-color: rgba(0,0,0,0.75);
  backdrop-filter: blur(5px);

  //
  &:not(.no-animation) {
    opacity: 0;
    visibility: hidden;

    .modal-body{
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s, visibility 0.5s;
    }

    &.active{
      visibility: visible;
      opacity: 1;
      .modal-body{
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.no-animation{
    transition: none;
    display: none;

    &.active{
      display: flex;
    }
  }

  .fixed-btn{
    width: 100%;
    @media (min-width: 1024px) {
      width: 800px;
    }
  }

  .modal-body {
    @apply w-500px;
    @apply bg-white shadow-md;
    position: relative;

    // hide scrollbar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    &.theme{
      width: 100%;
      @media (min-width: 1024px) {
        width: 800px;
      }

      .modal-content{
        @apply  pb-0 pt-0;
      }
    }

    &.location{
      width: auto;

      &.no-results {
        max-width: 600px;

        @media (min-width: 1024px) {
          .p-tag {
            width: 500px;
          }
        }
      }
    }

    &.inputs {
      @apply w-600px;
    }

    &.wide-inputs {
      @apply w-650px;
    }

    &.lg-wide-inputs {
      @apply lg-w-750px;
    }

    &.xl-wide-inputs {
      @apply lg-w-1080px;
    }

    .close-modal{
      position: absolute;
      top: 0px;
      right: 0px;
      margin-top: 20px;
      margin-right: 20px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    // mobile
    max-height: 100vh;
    @media (min-width: 1024px) {
      max-height: 98vh;
    }
  }
  .modal-title {
    @apply bg-wg-primary-gradient;

    &.core{
      @apply bg-core-primary-gradient;
    }
    &.error{
      @apply bg-wg-red-gradient;
    }
  }
  .modal-content {
    &.with-padding {
      @apply p-6;
    }

    @media (min-width: 1024px) {
      @apply p-6;
    }
  }
}

.w-550px {
  width: 550px;
}

.min-h-screen-minus-nav {
  min-height: calc(100vh - 140px);
}

.min-h-screen-minus-nav-and-top {
  min-height: calc(100vh - (140px + 300px));
}

.lg-min-h-screen-minus-bottom-bar {
  @media (min-width: 1024px) {
    min-height: calc(100vh - (80px + 112px));
  }
}

//
.lists-inside{
  ul, ol{
    display: block;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ul{
    @apply list-disc;
  }

  ol{
    @apply list-decimal;
  }
}

//
.link-inside{
  a{
    color: #12B3F9;
    &:hover{
      text-decoration: underline;
    }
    &.ignore, &.ignore:hover{
      text-decoration: none;
    }
  }

  &.core {
    a{
      color: #FFFFFF;
      text-decoration: underline;
    }
  }
}

.link-inside-white{
  a{
    @apply font-semibold;
    color: #FFFFFF;
    text-decoration: underline;
  }
}

.list-items-inside{
    ul{
      list-style-type: disc;
      list-style-position: inside;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    ol{
      list-style-type: decimal;
      list-style-position: inside;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
}

.footer-links{
  a{
    opacity: 0.6;
    margin-bottom: 0.75rem;
    &:hover{
      text-decoration: underline;
    }
  }
  a.locale {
    opacity: 1.0;
    @apply font-bold;
  }

  &.event-page{
    a{
      margin-right: 0;
      @media (min-width: 1024px) {
        margin-right: 0.75rem;
      }
    }
  }
}

.paragraph-content{
  p{
    margin: 1em 0px;
  }

  > p:first-child {
    margin-top: 0;
  }
}

.enquiry-label{
  font-weight: 600;
  color: black !important;
}

.theme-selection.loading {
  @apply opacity-25 pointer-events-none cursor-wait select-none;
}

.wg-team-img {
  clip-path: circle(50% at 50% 50%);
  outline: none;
  border: none;
  transition: 0.2s;
}

.wg-team-img:hover {
  transition: 0.4s;
  opacity: 0.7;
  outline: none;
  filter: brightness(30%);
}

.vacancy-circle {
  clip-path: circle(48% at 50% 50%);
  outline: none;
  border: none;
  color: #fff;
  text-align: center;
  background: #14B694;
  cursor: pointer;
  transition: 0.1s ease-in;
}

.vacancy-circle:hover {
  transition: 0.4s ease-out;
  transform: translateY(-10px);
  outline: none;
  box-shadow: 2px 6px 19px -9px #5c5c5c;
}

.circle-sm {
  width: 96px;
  height: 96px;
  line-height: 96px;
}

.bg-wg-light-green {
  &:hover {
    background-color: #E0F3EC;
  }
}

.bg-wg-light-purple {
  &:hover {
    background-color: #F3EDF8;
  }
}
