.h-min-100-vh {
  min-height: 100vh;
}

.lg-h-min-100-vh {
  @media (min-width: 1024px) {
    min-height: 100vh;
  }
}

.xl-h-min-100-vh {
  @media (min-width: 1280px) {
    min-height: 100vh;
  }
}

.lg-h-85-vh {
  @media (min-width: 1024px) {
    height: 85vh;
  }
}

.xl-h-85-vh {
  @media (min-width: 1280px) {
    height: 80vh;
  }
}

.xl-h-min-85-vh {
  @media (min-width: 1280px) {
    min-height: 80vh;
  }
}

.lg-h-min-85-vh {
  @media (min-width: 1024px) {
    min-height: 85vh;
  }
}

.h-38px {
  height: 38px;
}

.h-40px {
  height: 40px;
}

.min-h-40px {
  min-height: 40px;
}

.h-42px {
  height: 42px;
}

.h-50px {
  height: 50px;
}

.min-h-50px {
  min-height: 50px;
}

.lg-h-50px {
  @media (min-width: 1024px) {
    height: 50px;
  }
}

.h-60px {
  height: 60px;
}

.h-70px {
  height: 70px;
}

.lg-h-70px {
  @media (min-width: 1024px) {
    height: 70px;
  }
}

.h-80px {
  height: 80px;
}

.h-100px {
  height: 100px;
}

.lg-h-100px {
  @media (min-width: 1024px) {
    height: 100px;
  }
}

.lg-h-min-100px {
  @media (min-width: 1024px) {
    min-height: 100px;
  }
}

.h-120px {
  height: 120px;
}

.h-150px {
  height: 150px;
}

.h-185px {
  height: 185px;
}

.h-200px {
  height: 200px;
}

.md-h-250px {
  @media (min-width: 768px) {
    height: 250px;
  }
}

.h-sm-200px {
  @media (max-width: 767px) {
    height: 200px;
  }
}

.h-300px {
  height: 300px;
}

.md-h-300px {
  @media (min-width: 768px) {
    height: 300px !important;
  }
}

.lg-h-300px {
  @media (min-width: 1024px) {
    height: 300px;
  }
}

.h-400px {
  height: 400px;
}

.lg-h-400px {
  @media (min-width: 1024px) {
    height: 400px;
  }
}

.h-450px {
  height: 450px;
}

.lg-h-450px {
  @media (min-width: 1024px) {
    height: 450px;
  }
}

.h-500px {
  height: 500px;
}

.h-min-500px {
  min-height: 500px;
}

.lg-h-500px {
  @media (min-width: 1024px) {
    height: 500px;
  }
}

.h-600px {
  height: 600px;
}

.lg-h-600px {
  @media (min-width: 1024px) {
    height: 600px !important;
  }
}

.h-min-700px {
  min-height: 700px;
}

.md-h-700px {
  @media (min-width: 768px) {
    height: 700px !important;
  }
}

.h-800px {
  height: 800px;
}

.h-1000px {
  height: 1000px;
}

.w-1400px {
  width: 1400px;
}

.h-1400px {
  height: 1400px;
}

.lg-h-1400px {
  @media (min-width: 1024px) {
    height: 1400px;
  }
}

.min-h-600px {
  min-height: 600px !important;
}

.min-h-980px {
  min-height: 980px !important;
}

.max-h-200px {
  max-height: 200px;
}

.max-h-screen-80 {
  max-height: 80vh;
}

.lg-h-120vh {
  @media (min-width: 1024px) {
    height: 120vh;
  }
}

.h-75perc {
  height: 75%;
}

.lg-h-150vh {
  @media (min-width: 1024px) {
    height: 150vh;
  }
}

//

.w-38px {
  width: 38px;
}

.w-40px {
  width: 40px;
}

.min-w-40px {
  min-width: 40px;
}

.w-50px {
  width: 50px;
}

.min-w-50px {
  min-width: 50px;
}

.w-70px {
  width: 70px;
}

.w-80px {
  width: 80px;
}

.w-85px {
  width: 85px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-150px {
  width: 150px !important;
}

.lg-w-30 {
  @media (min-width: 1024px) {
    width: 30% !important;
  }
}

.w-30 {
  width: 30% !important;
}

.lg-w-150px-split {
  @media (min-width: 1024px) {
    width: calc(150px - 0.5rem) !important;
  }
}

.lg-w-150px {
  @media (min-width: 1024px) {
    width: 150px !important;
  }
}

.lg-w-200px-split {
  @media (min-width: 1024px) {
    width: calc(200px - 0.5rem) !important;
  }
}

.lg-w-200px {
  @media (min-width: 1024px) {
    width: 200px !important;
  }
}

.lg-min-w-150px {
  @media (min-width: 1024px) {
    min-width: 150px !important;
  }
}

.lg-min-w-200px {
  @media (min-width: 1024px) {
    min-width: 200px !important;
  }
}

.min-w-100px {
  min-width: 100px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.w-165px {
  width: 165px !important;
}

.w-170px {
  width: 170px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.w-200px {
  width: 200px !important;
}

.w-sm-200px {
  @media (max-width: 767px) {
    width: 200px;
  }
}

.lg-w-200px {
  @media (min-width: 1024px) {
    width: 200px !important;
  }
}

.lg-w-202px {
  @media (min-width: 1024px) {
    width: 202px !important;
  }
}

.w-250px {
  width: 250px !important;
}

.lg-w-250px {
  @media (min-width: 1024px) {
    width: 250px !important;
  }
}

.w-280px {
  width: 280px !important;
}

.w-300px {
  width: 300px !important;
}

.w-320px {
  width: 320px !important;
}

.h-320px {
  height: 320px !important;
}

.min-h-320px,
.min-h-80 {
  min-height: 320px !important;
}


.md-w-300px {
  @media (min-width: 768px) {
    width: 300px !important;
  }
}

.lg-w-300px {
  @media (min-width: 1024px) {
    width: 300px !important;
  }
}

.w-375px {
  width: 375px;
}

.w-400px {
  width: 400px !important;
}

.lg-w-400px {
  @media (min-width: 1024px) {
    width: 400px !important;
  }
}

.w-500px {
  width: 500px;
}

.md-w-500px {
  @media (min-width: 768px) {
    width: 500px !important;
  }
}

.lg-w-500px {
  @media (min-width: 1024px) {
    width: 500px !important;
  }
}

.w-600px {
  width: 600px;
}

.w-650px {
  width: 650px;
}

.lg-w-600px {
  @media (min-width: 1024px) {
    width: 600px !important;
  }
}

.lg-w-615px {
  @media (min-width: 1024px) {
    width: 615px !important;
  }
}

.max-w-100px {
  max-width: 100px;
}

.max-w-200px {
  max-width: 200px;
}

.min-w-500px {
  min-width: 500px;
}

.max-w-500px {
  max-width: 500px;
}

.max-w-700px {
  max-width: 600px;
}

.max-w-900px {
  max-width: 900px;
}

.lg-w-750px {
  @media (min-width: 1024px) {
    width: 750px;
  }
}

.lg-w-1080px {
  @media (min-width: 1024px) {
    width: 1080px;
  }
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-40 {
  min-width: 10rem;
}

.ml-1\/4 {
  margin-left: 25%;
}

div.fixed-label {
  align-items: start !important;
}

label.fixed-label {
  height: 42px;
  line-height: 42px;
  vertical-align: middle;
}

/* Video aspect ratio tricks */
.h-437-px {
  height: 437px;
}

.md-h-300px {
  @media (min-width: 1024px) {
    height: 300px !important;
  }
}

.lg-h-437px {
  @media (min-width: 1024px) {
    height: 437px !important;
  }
}

/* End video aspect ratio tricks */