.between-section-margin{
  @apply my-6;
}

@screen md {
  .between-section-margin{
    @appply my-12;
  }
}

@screen lg {
  .between-section-margin{
    @apply my-16;
  }
}

.pricing_component_benefits {
  p {
    margin-bottom: 1.2em;

    b, strong {
      color: #444444;
      font-weight: 800;
    }
  }

}