.theme-carousel {
  .slick-arrow {
    &.card-carousel-arrow {
      width: 40px;
      height: 40px;
      border: 2px solid white;
      background-color: transparent;

      &:hover {
        background-color: rgba(255, 255, 255, 0.103);
      }

      &::before {
        font-size: 15px;
        margin-left: 0px;
      }

      &.slick-prev {
        left: -48px;
        &::before {
          margin-left: 0px;
        }
      }

      &.slick-next {
        right: -48px;
        &::before {
          margin-right: 0px;
        }
      }

      &.arrow-black {
        border: 2px solid black;

        &:hover {
          background-color: rgba(212, 212, 212, 0.164);
        }

        &::before {
          color: black;
        }
      }
    }
  }

  &.card-carousel {
    .slick-dots {
      position: relative;
      bottom: 0px;
      margin-top: 2rem;
      display: flex;
      justify-content: center;

      li {
        padding-left: 5px;
        padding-right: 5px;

        button {
          width: 40px;
          height: 8px;
          background-color: #ffffffa4;
          border-radius: 8px;
        }

        &.slick-active {
          button {
            background-color: #ffffff;
          }
        }
      }
    }

    &.carousel-white {
      .slick-dots {
        li {
          button {
            background-color: #acacaca4;
          }

          &.slick-active {
            button {
              background-color: #02BA94 !important;
            }
          }
        }
      }
    }
  }
}
