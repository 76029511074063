.arrow-click{
  border: 2px solid white;
  border-radius: 50%;
  background : #98E06E;
  padding: 15px;
}

.copy-url{
  color: white;
  background : #98E06E;
  
  @media (min-width: 1024px) {
    //
    border: 2px solid white;

    // 
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.event-url{
  // clipboardJS doesn't work with hidden or invisible elements, so we somewhere on the page, but away from user's eyes
  @media (max-width: 1024px) {
    opacity: 0;
    position: absolute;
    z-index: -9999;
    pointer-events: none;
  } 
}