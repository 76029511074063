@screen lg {
  .hover-boxes {
    .hover-box {
      transition: all 0.5s ease;
      transition-property: box-shadow, opacity;

      &.inactive {
        opacity: 0.33;
      }
      &.active {
        opacity: 1.0;
        @apply shadow-xl;
      }
    }
  }
}

.manage-event-tab-item {
  @apply inline-block pb-1 border-black;
  @apply select-none;

  &:hover {
    @apply border-b-2;
  }
  &.selected {
    @apply font-bold border-b-2;
  }
}

.account-tabs {
  @apply border-2 border-gray-300;

  &.selected {
    @apply border-wg-green-300;

    &.partner {
      @apply border-wg-purple-500;
    }
  }
}
