.blue-sidebar {
  &.demo-sidebar {
    @media (min-width: 1024px) {
      width: 500px;
    }

    &::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      box-shadow: 9px 3px 15px -4px rgba(0, 0, 0, 0.1);
    }
  }
  &.user-purchase-sidebar{
    @media (max-width: 1244px) {
      height: 400px;
      position: fixed;
      top: 5rem;
      right: 0;
      left: 0;
    }

    @media (max-width: 900px) {
      height: 90%
    }

    box-shadow: 0px 1px 18px 2px rgba(0, 0, 0, 0.2)
  }
}

.proposal-sidebar-placeholder {
  min-width: calc(500px - 2rem);
}
