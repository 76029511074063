.landing-about-stat {
  @media (max-width: 1024px) {
    min-height: 300px;
    max-height: 300px;
    width: 100%;
  }
  @media (min-width: 1024px) {
    min-width: 300px;
    width: 33%;
  }
}
