.landing-bg-circle{
  width: 465px;
  opacity: 0.06;
  user-select: none;
  pointer-events: none;

  &.footer-l{
    bottom: -200px;
    left: -150px;
  }
  &.footer-r{
    bottom: 150px;
    right: -250px;
  }

  &.about-t{
    top: -250px;
    left: 100px;
  }

  &.about-r{
    top: 100px;
    right: -250px;
  }

  &.about-b{
    bottom: 500px;
    left: -250px;
  }

  &.section-l{
    top: 300px;
    left: -250px;
    opacity: 0.1;
  }
  &.section-r{
    bottom: 200px;
    right: -250px;
    opacity: 0.1;
  }
  &.section-b{
    bottom: -300px;
    left: -250px;
    opacity: 0.1;
  }
  &.hero-tl{
    top: -250px;
  }

  &.hero-l{
    left: -250px;
    bottom: 150px;
  }
  &.hero-tr{
    right: -50px;
    top: -300px;
  }
  &.hero-r{
    right: -250px;
    top: 100px;
  }
  &.hero-bl{
    bottom: -300px;
    left: 100px;
  }
  &.hero-bm{
    bottom: -140px;
    right: 285px;
    width: 425px;
  }
}

// demo page
.bg-shape{
  opacity: 0.1;
  &.demo-tr{
    top: -2rem;
    right: -3rem;
  }
  &.demo-bl{
    bottom: -24rem;
    left: -18rem;
  }
  &.demo-br{
    opacity: 0.15;
    bottom: -2rem;
    right: -3rem;
  }
}

//product page
.bg-circle {
  border-radius: 50%;
  position: absolute;
  top: -10%;
  width: 40rem;
  height: 40rem;
  background: rgb(100, 100, 100);
  background: radial-gradient(circle, rgb(110, 110, 110) 0%, rgba(192, 183, 183, 0) 63%, rgba(196,196,196,0) 100%);
  z-index: 1;
}

.bg-circle.right {
  left: -10%;
}

.video-feature {
  position: absolute;
  top: 2%;
  height: 89%;
  z-index: 5;
  border-radius: 5%;
}

@media only screen and (max-width: 1024px) {
  .video-feature {
   height: 82%;
   top: 9%;
  }
}
