.toggle-switch {
  position: relative;
  width: 44px;
  height: 24px;
  border-radius: 100px;
  background-color: #ddd;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0,0,0,.05);
  
  .check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
  }
  
    .check:checked ~ .track {
      box-shadow: inset 0 0 0 20px #9F7AEA;
    }

    .check.desaturated:checked ~ .track {
      box-shadow: inset 0 0 0 20px #cec4e3;
    }

    .check.core:checked ~ .track{
      box-shadow: inset 0 0 0 20px #319795;
    }

    .check.core.desaturated:checked ~ .track {
      box-shadow: inset 0 0 0 20px #a5c7c6;
    }
  
    .check:checked ~ .switch {
      right: 2px;
      left: 22px;
      transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
      transition-property: left, right;
      transition-delay: .05s, 0s;
    }
  
  .switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 22px;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-property: left, right;
    transition-delay: 0s, .05s;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
  }
  
  .track {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    box-shadow: inset 0 0 0 2px rgba(0,0,0,.05);
    border-radius: 40px;
  }
}
