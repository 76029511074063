.font-bold {
  .feather {
    stroke-width: 3;
  }
}

.boolean {
  label {
    @apply select-none;

    a {
      @apply text-wg-blue-200 underline;

      &:hover {
        @apply no-underline;
      }
    }
  }

  &.disabled {
    @apply opacity-25;
  }
}

.feather-16 {
  width: 16px;
  height: 16px;
}

.feather-20 {
  width: 20px;
  height: 20px;
}

.feather-24 {
  width: 24px;
  height: 24px;
}

.feather-32 {
  width: 32px;
  height: 32px;
}

.feather.font-semi {
  stroke-width: 2;
  margin-bottom: 3px;
}

.feather.font-light {
  stroke-width: 1.25;
}

.StripeElement {
  background-color: #fff;
  border-width: 1px;
  border-color: #e2e8f0;
  border-radius: 0.25rem;
  padding: 8px 16px;
  margin-bottom: 8px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.hover-trigger .hover-target {
  display: none;

  a {
    width: 100%;
    display: inline-block;
  }
}

.hover-trigger:hover {
  .hover-target {
    display: block;
  }

  .rounded-b-none-hover {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

//

.lg-pt-50 {
  padding-top: 16rem !important;
}

//

.hero-image {
  @media (min-width: 1280px) {
    margin-bottom: 0;
    bottom: 0;
    margin-left: -100px;
    width: 700px;
  }

  @media (min-width: 1280px) {
    &.core {
      bottom: 8vw;
      width: 50vw;
      margin-left: -70px;
    }
  }

  @media (min-width: 1440px) {
    margin-left: -100px;
    width: 780px;
  }

  @media (min-width: 1800px) {
    margin-left: -100px;
    width: 880px;
  }

  @media (max-height: 1280px) {
    width: 650px;
  }

  @media (max-width: 400px) {
    height: 240px;
    object-fit: contain;
  }
}

.banner-hero-image {
  margin-bottom: -106px;

  @media (min-width: 1280px) {
    margin-bottom: 0;
    bottom: 0;
    margin-left: -150px;
    width: 600px;
  }

  @media (min-width: 1440px) {
    margin-left: -50px;
    width: 600px;
  }
}

.text-hero {
  font-size: 28px;
  line-height: 36px;
  text-shadow: 1px 0px 8px rgba(0, 0, 0, 0.1);

  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 52px;
  }
}

.number-bottom-left {
  position: relative;
  text-align: -webkit-center;
  .number {
    text-align: center;
    box-sizing: content-box;

    color: white;
    border-radius: 50%;
    background: #98e06e;

    position: absolute;
    left: 4rem;
    transform: translateX(-50%);

    //
    font-size: 36px;
    width: 65px;
    line-height: 65px;
    top: 8rem;

    @media (min-width: 1024px) {
      font-size: 36px;
      width: 65px;
      line-height: 65px;
      top: 8rem;
    }
  }

  &.small {
    .number {
      font-size: 16px;
      width: 32px;
      line-height: 32px;
      top: 4rem;
      left: 0.5rem;
    }
  }
}

.inset-b-0 {
  bottom: 0;
}

.bg-black-real {
  background-color: #000;
}

.h-250px {
  height: 250px;
}

@media (min-width: 1024px) {
  .lg\:w-250px {
    width: 250px;
  }
}
