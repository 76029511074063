.landing-hero {
  .btn-video {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20vw;

    @media (min-width: 1024px) {
      right: 8vw;
      left: initial;
    }
  }
}

@media (max-height: 1280px) and (min-width: 1600px) {
  .landing-hero {
    .min-hero-height {
      height: 700px !important;
      min-height: 700px !important;
    }
    .hero-container {
      height: 700px !important;
    }
  }
}

.scale {
  transform: scale(1.2);

  @media (min-width: 1020px) {
    transform: scale(1.6);
  }
}

.landing-hero-slick {
  min-height: 288px;
  @media (min-width: 768px) {
    min-height: 395px;
  }
  visibility: hidden;

  &.slick-initialized {
    visibility: visible;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 16px;
    line-height: 16px;
  }
}

.device-wrapper {
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: auto;

  @media (min-width: 700px) {
    width: 50%;
  }

  @media (min-width: 1280px) {
    width: 50%;
  }

  .rotate {
    transform: rotate(17deg);
  }

  .phone {
    height: 100%;
    width: 100%;
    @media (min-width: 1280px) {
      transform: scale(1.3);
    }
  }
  .screen {
    width: 87%;
    height: auto;
    position: absolute;
    top: 15px;
    left: 52%;
    transform: translate(-50%, 0);

    @media (min-width: 1280px) {
      top: 13px;
      left: 49%;
      transform: translate(-46%, 0) scale(1.3);
    }
  }
}

.card-animation {
  // animation-delay: 2s;
  width: 224px;

  // &.tl {
  //   animation: diagonal-movement-tl 5s infinite ease-in-out;
  // }
  // &.tr {
  //   animation: diagonal-movement-tr 5.5s infinite ease-in-out;
  // }
  // &.br {
  //   animation: diagonal-movement-br 6.5s infinite ease-in-out;
  // }
  // &.bl {
  //   animation: diagonal-movement-bl 4.5s infinite ease-in-out;
  // }
}

@keyframes slow-pulse {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

@keyframes diagonal-movement-tl {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, 5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes diagonal-movement-tr {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(5px, -5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes diagonal-movement-br {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, -5px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes diagonal-movement-bl {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, 5px);
  }

  100% {
    transform: translate(0, 0);
  }
}
