.btn-primary-borderless{
  @apply cursor-pointer py-2 font-bold my-3 text-center text-white bg-black;

  border: 0;
  border-radius : 3px;
  width: 100%;
  margin: 0 auto;
  opacity: 1;

  &:hover{
    filter: brightness(0.95);
  }

  &.btn-core{
    background: linear-gradient(60deg, rgba(2, 186, 148, 1) 0%, rgba(2, 144, 159, 1) 100%) !important;
  }

  &.btn-partner{
    background : linear-gradient(90deg, rgba(24, 176, 249, 1) 0%, rgba(126, 127, 249, 1) 96.04%) !important;
  }
}

// sizes

.btn-small{
  @apply py-1 #{!important};
}

.btn-purchase {
  @apply block text-center cursor-pointer font-bold bg-wg-green-300 text-white w-full py-3 rounded-none;

  &:hover {
    @apply bg-green-400;
    filter: brightness(1);
  }
  &.selected {
    @apply bg-green-400;
    filter: brightness(1);
  }
  &:focus {
    @apply outline-none;
  }

  &.partner {
    background-color: #9055e2;

    &.selected {
      background-color: #63389e;
    }
    &:hover {
      background-color: #63389e;
    }
  }
  &:not(.btn-purchase-skip-disable-styling):disabled, &:not(.btn-purchase-skip-disable-styling).disabled {
    @apply bg-blue-400 cursor-wait;
  }
}

.btn-purchase-on {
  @apply block text-center cursor-pointer font-bold bg-blue-400 cursor-default text-white w-full py-3 rounded-none;

  &:focus {
    @apply outline-none;
  }
}
