// general wrappers
.main-white-container {
  min-height: 720px;
  @media (min-width: 1024px) {
    width: 1080px;
  }
}

.event-date {
  background: url("~images/icon_calendar.svg") no-repeat scroll 18px 12px;
  background-size: 18px;
  padding-left: 50px !important;

  &.core-icon {
    background: url("~images/core_icon_calendar.svg") no-repeat scroll 14px 9px;
    background-size: 25px !important;
  }
}

.participants {
  background: url("~images/icons/users.svg") no-repeat scroll 18px 12px;
  background-size: 18px;
  padding-left: 50px !important;

  &.core-icon {
    background: url("~images/icons/users-core.svg") no-repeat scroll 14px 9px;
    background-size: 25px !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.add-checkbox {
  position: relative;
  //
  &:after {
    content: " ";
    position: absolute;
    height: 18px;
    width: 18px;
    background: url("~images/tickbox.svg") no-repeat scroll 100% 100%;
    bottom: -6px;
    right: -6px;
  }
}

.start-date-dropdown-input {
  //
  width: 100%;
  color: #bfbfc6;
  background-color: #fff;
  // border-width: 1px;
  border-color: #e2e8f0;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.5;
}


// dropdown in about page
.dropdown-hour-container {
  position: relative;
  //
  .selected-value {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 50px !important;
    cursor: pointer;
    p {
      color: #141414;
      cursor: pointer;
      .empty {
        color: #cfd7df;
        opacity: 1;
      }
      .feather {
        color: #141414;
        opacity: 0.25;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  //
  ul {
    background: #ffffff;
    // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
    z-index: 500;
    //
    li {
      width: 100%;
      padding: 4px 0;
      padding-left: 15px;
      // color: #080421;
    }
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    &:hover {
      li {
        &:hover {
          background: linear-gradient(
            -90deg,
            rgba(18, 179, 249, 1) 0%,
            rgba(126, 127, 249, 1) 96.04%
          );
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
.core {
  .dropdown-hour-container {
    ul {
      &:hover {
        li {
          &:hover {
            background: #02ba94;
          }
        }
      }
    }
  }
}

.core {
  .selected-hour {
    background: #02ba94;
    color: #fff;
  }
}

.selected-hour {
  background: linear-gradient(
    -90deg,
    rgba(18, 179, 249, 1) 0%,
    rgba(126, 127, 249, 1) 96.04%
  );
  color: #fff;
  cursor: pointer;
}

.core {
  .flatpickr-weekday {
  color: #fff !important;
  opacity: 0.8;
  }
}

.flatpickr-day.selected {
  font-weight: bold !important;
  border-color: transparent !important;
}

#details_date_form {
  .flatpickr-calendar:before, .flatpickr-calendar:after {
    display: none !important;
  }
}
