.slide-in {
  position: absolute;
  left: 100vw;
  -webkit-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
}

@-webkit-keyframes slideIn{
  100% { left: 0; }
}

@keyframes slideIn {
  100% { left: 0; }
}