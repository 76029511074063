.btn-pill {
  @apply rounded-full #{!important};
  @apply py-2 text-white px-8;
  user-select: none;
}

.btn-pill-less {
  @apply btn-pill;

  border-radius: 8px !important;
}

.btn-blue {
  background-color: #12b3f9;
}
.btn-red {
  background-color: #f97d7d;
}
.btn-purple {
  background-color: #7e7ff9;
}
.btn-yellow {
  background-color: #f7bd73;
}
.btn-green {
  background-color: #00b196;
  border: none;

  &:not(.btn-static) {
    &:hover {
      background-color: darken(#00b196, 4%);
    }
  }
}
.btn-pink {
  background-color: #f9129d;
}
.btn-brown {
  background-color: #753425;
}
.btn-light-gray {
  background-color: #e2e8f0;
}
