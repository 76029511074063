.tippy-tooltip {
  padding: 10px;
}

.tippy-tooltip.light-theme {
  color:#26323d;
  box-shadow:0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15);
  background-color:#fff;
}

.tippy-tooltip.light-theme[x-placement^=top] .tippy-arrow {
  border-top:8px solid #fff;
  border-right:8px solid transparent;
  border-left:8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=bottom] .tippy-arrow {
  border-bottom:8px solid #fff;
  border-right:8px solid transparent;
  border-left:8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=left] .tippy-arrow {
  border-left:8px solid #fff;
  border-top:8px solid transparent;
  border-bottom:8px solid transparent;
}

.tippy-tooltip.light-theme[x-placement^=right] .tippy-arrow {
  border-right:8px solid #fff;
  border-top:8px solid transparent;
  border-bottom:8px solid transparent;
}

.tippy-tooltip.light-theme[data-animatefill] {
  background-color:initial;
}

.tippy-tooltip.light-border-theme {
  background-color:#fff;
  background-clip:padding-box;
  border:1px solid rgba(0,8,16,.15);
  color:#26323d;
  box-shadow:0 3px 14px -.5px rgba(0,8,16,.08);
}

.tippy-tooltip.light-border-theme .tippy-arrow:after,.tippy-tooltip.light-border-theme .tippy-arrow:before,.tippy-tooltip.light-border-theme .tippy-roundarrow:after,.tippy-tooltip.light-border-theme .tippy-roundarrow:before {
  content:"";
  position:absolute;
  z-index:-1;
}

.tippy-tooltip.light-border-theme .tippy-roundarrow:after {
  background-image:url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0IiB2aWV3Qm94PSIwIDAgMTggNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA3czIuMDIxLS4wMTUgNS4yNTMtNC4yMThDNi41ODQgMS4wNTEgNy43OTcuMDA3IDkgMGMxLjIwMy0uMDA3IDIuNDE2IDEuMDM1IDMuNzYxIDIuNzgyQzE2LjAxMiA3LjAwNSAxOCA3IDE4IDd6IiBmaWxsPSIjMzMzIiBmaWxsLW9wYWNpdHk9Ii4yMzUiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  background-size:18px 7px;
  width:18px;
  height:7px;
  left:0;
  top:0;
  fill:rgba(0,8,16,.15);
}

.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-roundarrow:after {
  top:1px;
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg);
}

.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow {
  border-top-color:#fff;
}

.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow:after {
  border-top:7px solid #fff;
  top:-7px;
}

.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow:before {
  border-top:7px solid rgba(0,8,16,.2);
  bottom:-1px;
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-roundarrow:after {
  top:-1px;
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow {
  border-bottom-color:#fff;
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow:after {
  border-bottom:7px solid #fff;
  bottom:-7px;
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow:before {
  border-bottom:7px solid rgba(0,8,16,.2);
  bottom:-6px;
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-roundarrow:after {
  left:1px;
  top:0;
  -webkit-transform:rotate(90deg);
  transform:rotate(90deg);
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow {
  border-left-color:#fff;
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow:after {
  border-left:7px solid #fff;
  left:-7px;
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow:before {
  border-left:7px solid rgba(0,8,16,.2);
  left:-6px;
}

.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-roundarrow:after {
  left:-1px;
  top:0;
  -webkit-transform:rotate(-90deg);
  transform:rotate(-90deg);
}

.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow {
  border-right-color:#fff;
}

.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow:after {
  border-right:7px solid #fff;
  right:-7px;
}

.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow:before {
  border-right:7px solid rgba(0,8,16,.2);
  right:-6px;
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow,.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-roundarrow,.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow,.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-roundarrow {
  -webkit-transform:translateX(-1px);
  transform:translateX(-1px);
}

.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow:after,.tippy-tooltip.light-border-theme[x-placement^=bottom] .tippy-arrow:before,.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow:after,.tippy-tooltip.light-border-theme[x-placement^=top] .tippy-arrow:before {
  left:-7px;
  border-left:7px solid transparent;
  border-right:7px solid transparent;
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow,.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-roundarrow,.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow,.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-roundarrow {
  -webkit-transform:translateY(-1px);
  transform:translateY(-1px);
}

.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow:after,.tippy-tooltip.light-border-theme[x-placement^=left] .tippy-arrow:before,.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow:after,.tippy-tooltip.light-border-theme[x-placement^=right] .tippy-arrow:before {
  top:-7px;
  border-top:7px solid transparent;
  border-bottom:7px solid transparent;
}

.tippy-tooltip.light-theme .tippy-backdrop,.tippy-tooltip.light-border-theme .tippy-backdrop {
  background-color:#fff;
}

.tippy-tooltip.light-theme .tippy-roundarrow,.tippy-tooltip.light-border-theme .tippy-roundarrow {
  fill:#fff;
}
