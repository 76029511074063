input[type=range] {
  -webkit-appearance: none;
  margin: 13px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ebf8ff;
  border-radius: 4px;
}
input[type=range]::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  height: 32px;
  width: 32px;
  margin-top: -13px;
  border: 5px solid #fff;
  border-radius: 16px;
  background-color: #7e7ff9;
  box-shadow: 0px 1px 18px 2px rgba(0, 0, 0, 0.15);
}

input[type=range]:focus::-webkit-slider-thumb {
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ebf8ff;
  border-radius: 4px;
}
input[type=range]::-moz-range-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  height: 32px;
  width: 32px;
  margin-top: -13px;
  border: 5px solid #fff;
  border-radius: 16px;
  background-color: #7e7ff9;
  box-shadow: 0px 1px 18px 2px rgba(0, 0, 0, 0.2);
}

input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;

  width: 100%;
  height: 8px;
  padding: 22px 0;
  cursor: pointer;
  border-radius: 4px;
}
input[type=range]::-ms-fill-lower {
  background: #ebf8ff;
}
input[type=range]::-ms-fill-upper {
  background: #ebf8ff;
}
input[type=range]::-ms-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  height: 32px;
  width: 32px;
  margin-top: 0px;
  border: 5px solid #fff;
  border-radius: 20px;
  background-color: #7e7ff9;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.2);
}
input[type=range]:focus::-ms-fill-lower {
}
input[type=range]:focus::-ms-fill-upper {
}

.core, .body-core {
  input[type=range]::-webkit-slider-thumb {
    background-color: #02BA94;
  }
  input[type=range]::-moz-range-thumb {
    background-color: #02BA94;
  }
  input[type=range]::-ms-thumb {
    background-color: #02BA94;
  }

  .flatpickr-calendar {
    box-shadow: none;
  }

  .flatpickr-day.selected, .flatpickr-day.selected:hover {
    background: #02BA94;
    border-color: #02BA94;
  }
}

input[type=range].rounded-core::-webkit-slider-runnable-track {
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
}
input[type=range].rounded-core::-webkit-slider-thumb {
  height: 38px;
  width: 38px;
  margin-top: -14px;
  border-radius: 19px;
  background-color: #0A0422;
  border: 4px solid #2EA7FA !important;
}
input[type=range].rounded-core::-moz-range-track {
  height: 12px;
  background: transparent;
  border-radius: 6px;
}
input[type=range].rounded-core::-moz-range-thumb {
  height: 38px;
  width: 38px;
  margin-top: -14px;
  background-color: #0A0422;
  border: 4px solid #2EA7FA !important;
  border-radius: 19px;
}
input[type=range].rounded-core::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;

  width: 100%;
  height: 12px;
  padding: 24px 0;
  cursor: pointer;
  border-radius: 6px;
}
input[type=range].rounded-core::-ms-thumb {
  height: 38px;
  width: 38px;
  background-color: #2BB096;
  border: 4px solid #2BB096 !important;
  border-radius: 38px;
}

// used in pricing calculator
input[type=range].pricing::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  border-radius: 6px;
}
input[type=range].pricing.goose-green::-webkit-slider-runnable-track {
  background-color: #bfe7e0;
}
input[type=range].pricing.goose-purple::-webkit-slider-runnable-track {
  background-color: #e0e0fd;
}

input[type=range].pricing::-webkit-slider-thumb {
  height: 26px;
  width: 26px;
  margin-top: -7px;
  border-radius: 13px;
  border: none;
}
input[type=range].pricing.goose-green::-webkit-slider-thumb {
  background-color: #2BB096;
}
input[type=range].pricing.goose-purple::-webkit-slider-thumb {
  background-color: #9055e2;
}

input[type=range].pricing::-moz-range-track {
  height: 12px;
  border-radius: 6px;
}
input[type=range].pricing.goose-green::-moz-range-track {
  background: #bfe7e0;
}
input[type=range].pricing.goose-purple::-moz-range-track {
  background: #e0e0fd;
}

input[type=range].pricing::-moz-range-thumb {
  height: 26px;
  width: 26px;
  margin-top: -7px;
  border: none;
  border-radius: 13px;
}
input[type=range].pricing.goose-green::-moz-range-thumb {
  background-color: #2BB096;
}
input[type=range].pricing.goose-purple::-moz-range-thumb {
  background-color: #9055e2;
}

.form-check {
  cursor: pointer;
  > label, > input {
    cursor: pointer;
  }

  > label {
    margin-left: 10px;
  }
  & + .form-check {
    margin-top: 5px;
  }

  & + .invalid-feedback {
    margin-top: 10px;
    font-size: 12px;
    color: #c53030;
  }
  > input.is-invalid + label {
    color: #c53030;
  }
}
