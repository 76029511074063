// headroom
.headroom {
  will-change: transform;
  transition: transform 300ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
.background-image-behind, .headroom--not-top{
  background : linear-gradient(60deg, rgba(144, 85, 226, 1) 0.95%, rgba(118, 96, 226, 1) 100%);
  border-bottom: 2px solid white;
}

.headroom.core {
  transition: opacity 200ms linear;
  background: none;

  &.bg-has-image {
    // font-weight: 500;
  }
  .bg-div {
    transition: opacity 200ms linear;
  }
  .bg-shapes {
    transition: opacity 200ms linear;
  }

  &.headroom--pinned, &.headroom--unpinned {
    transform: none;
  }
  &.headroom--not-top {
    border-bottom: none;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    &.bg-has-image {
      // font-weight: 400;
    }

    .bg-div {
      opacity: 1;
    }
    .bg-shapes {
      opacity: 0;
    }
  }
}

.headroom.persistent-shadow {
  border-bottom: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
