// blog index

// blog post
.blog-wrapper{
  .main-body{
    h1 {
      font-weight: 600;
      margin-bottom: 1.5rem;
      font-size: 40px;
    }
    h2 {
      font-weight: 600;
      margin-bottom: 1.5rem;
      font-size: 1.75em;
    }
    p {
      font-size: 18px;
      margin-bottom: 1rem;
      line-height : 32px;
    }
  }
}

.article-wrapper{
  @media (min-width: 1024px) {
    transition: all 0.25s ease !important;
    height: auto;
    &:hover{
      box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
      transform: translateY(-0.5rem);
    }
  }
}

.article-button-wrapper {
  .btn-primary {
    transition: all 0.1s ease !important;
  }

  &:hover {
    .btn-primary {
      @apply bg-blue-700;
      filter: brightness(90%);
    }
  }
}
