.navbar-dropdown {

  .navbar-dropdown-list {
    left: 50%;
    right: auto;
    transform: translateX(-50%) translateY(-5px) scale(.97);
    transition:all .3s ease;
    opacity: 0.0;
    pointer-events: none;
  }

  .navbar-dropdown-basket {
    left: -30% !important;
  }

  &:hover {
    .navbar-dropdown-list {
      opacity: 1.0;
      transform: translateX(-50%) translateY(0px) scale(1);
      pointer-events: initial;
    }
  }

}

.address-details {
  p:after {
    content: "";
    display: inline-block;
    width: 0px;
  }
}

.slick-loading .slick-list {
  background: #fff !important;
}
