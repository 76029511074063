// font-awesome
.fab:not(.untouched) {
  border-radius: 9999px;
  color: #080421;
  background: white;
  font-size: 15px;
  width: 25px;
  line-height: 25px;
  text-align: center;
}

// embeded video (force aspect ratio)
[data-oembed] {
  position: relative;
  padding-top: 56.25%; // 16:9

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// force square
.square {
  overflow: hidden;
  height: 0;
  padding-top: 100%;
}

// loader
.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.loader {
  margin: 30px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 7px solid rgba(137,88,227, 0.2);
  border-right: 7px solid rgba(137,88,227, 0.2);
  border-bottom: 7px solid rgba(137,88,227, 0.2);
  border-left: 7px solid #8958e3;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal {
  .is-invalid + div {
    font-size: 12px;
  }
}

.user-inputs, .address-inputs, form.new_customer {
  .is-invalid {
    margin-bottom: 0.25em;
    & + div {
      display: block;
      font-size: 12px;
    }
  }
}

// slick
.slick-dots{
  li{
    button{
      &::before{
        font-size: 11px;
        line-height: 11px
      }
    }
  }
}

/* the slides */
.slick-social{
  .slick-slide {
    margin: 0 10px;
  }
}

//
.vertical-line-left{
  &::before{
    content: none;
  }
  @media (min-width: 1024px) {
    position: relative;
    padding-left: 2rem;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: #8958e3;
      border-radius: 15px;
    }

    &.outside {
      padding-left: 0;

      &::before{
        left: -1rem;
      }
    }
  }

  &.line-white{
    &::before{
      background: #FFF;
    }
  }

  &.line-red{
    &::before{
      background: #F97D7D;
    }
  }

  &.line-purple{
    &::before{
      background: #8958e3;
    }
  }

  &.line-orange{
    &::before{
      background: #FFDD94;
    }
  }

  &.line-aqua{
    &::before{
      background: #86E3CE;
    }
  }

  &.line-green{
    &::before{
      background: #02BA94;
    }
  }

  &.line-yellow{
    &::before{
      background: #FAFFD1;
    }
  }

  &.line-blue{
    &::before{
      background: #12B3F9;
    }
  }
}

.horizontal-line-bottom{
  position: relative;
  padding-bottom: 1rem;
  &::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 6rem;
    background: #8958e3;
    border-radius: 15px;
    height: 4px;
  }
}

.horizontal-line-bottom-gray-small{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    bottom: -2rem;
    left: -2rem;
    width: 1em;
    background: rgba(255, 255, 255, 0.3);;
    border-radius: 15px;
    height: 3px;
  }
}

.horizontal-line-left{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 1.75rem;
    left: -4.5rem;
    width: 3.5rem;
    background: #8958e3;
    border-radius: 15px;
    height: 3px;
  }
}

// grid
.grid-photos{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 250px);
    grid-gap: 10px;
  }
}

.grid-vault{
  >article{
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (min-width: 1024px) {
   >article{
     width: 300px;
   }
  }
}

.dark-bg-overlay{
  &:after{
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background-color: rgba(0,0,0,0.75);
  }
}

.bg-semitransparent {
  background-color: rgba(0,0,0,0.75);
}
