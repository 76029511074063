.location-select{
  background: url("~images/location_pin.svg") no-repeat scroll 18px 12px;
  background-size: 12px;
  padding-left: 39px !important;

  &.core{
    background: url("~images/core_location_pin.svg") no-repeat scroll 18px 12px;
  }
}

.validation{
  text-align: center;
  background : #F9D4D9;
  background : rgba(249, 212, 217, 1);
  border-style : Solid;
  border-color : #E0AAB9;
  border-color : rgba(224, 170, 185, 1);
  border-width : 1px;
  border-radius : 3px;
  font-size : 14px;
  line-height : 37px;
  color : #B57C8D;
}

.new_partner_enquiry {
  .is-invalid + div {
    font-size: 12px;
  }
}

.inline-form {
  p + p {
    margin-top: 20px;
  }
  p.with-inputs {
    line-height: 50px;
  }

  input[type="text"], input[type="email"] {
    margin-left: 5px;
    margin-right: 5px;
    width: 210px;
  }
}
