.header-menu-link {
  @apply w-full block px-4 py-3 text-left text-gray-700;

  &:hover {
    @apply bg-gray-100 text-gray-900;
  }

  &:focus {
    @apply outline-none bg-gray-100 text-gray-900;
  }
}